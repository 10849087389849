<template>
  <section>
    <h5 class="mt-4 mb-4">{{$_strings.order.MODA_INFO}}</h5>
    <v-data-table
      :headers="orderDetails && orderDetails.transportModel !== 'LAND' ? headerMultimodaInfo : headerModaInfo"
      calculate-widths
      hide-default-footer
      :items="itemsModaInfo"
      class="elevation-1 rounded"
    >
      <template v-slot:[`item.estimateDeparture`]="{item}">
        <p class="ma-0">{{dateFormat(item.estimateDeparture)}}</p>
        <p v-if="item.estimateDeparture">{{timeFormat(item.estimateDeparture)}}</p>
      </template>
      <template v-slot:[`item.estimateArrival`]="{item}">
        <p class="ma-0">{{dateFormat(item.estimateArrival)}}</p>
        <p v-if="item.estimateArrival">{{timeFormat(item.estimateArrival)}}</p>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    itemsModaInfo: {
      type: Array,
      default: () => [],
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Moda Info
      headerModaInfo: [
        {
          text: this.$_strings.order.TYPE_TRANSPORTER,
          value: 'transportTypeName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.VEHICLE_NUMBER,
          value: 'companyTransportNo',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.DRIVER,
          value: 'companyDriverName',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: this.$_strings.order.NO_TELP,
          value: 'companyDriverPhone',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      // multimoda
      headerMultimodaInfo: [
        {
          text: this.$_strings.order.NO_CONTAINER,
          value: 'containerNo',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.NO_CRUISE_FLIGHTS,
          value: 'flightCruiseNo',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.SHIP_NAME,
          value: 'transportTypeName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.CRUISE_NAME_MASKAPAI,
          value: 'flightCruiseName',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.ESTIMATION_OF_DEPARTURE,
          value: 'estimateDeparture',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
        {
          text: this.$_strings.order.ESTIMATION_ARRIVED,
          value: 'estimateArrival',
          class: 'black--text amber accent-3 text-capitalize',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>
