var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$_strings.order.LOCATION_MAP))])]),_c('v-col',[_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":5,"map-type-id":"terrain"}},[_c('section',[(_vm.shipmentDetail.originLocation)?_c('GmapMarker',{attrs:{"position":{
            lat: _vm.shipmentDetail.originLocation.latitude,
            lng: _vm.shipmentDetail.originLocation.longitude
          },"clickable":true,"icon":{url : require('@/assets/images/map-marker-origin.png')}}}):_vm._e(),(_vm.shipmentDetail.destinationLocation)?_c('GmapMarker',{attrs:{"position":{
            lat: _vm.shipmentDetail.destinationLocation.latitude,
            lng: _vm.shipmentDetail.destinationLocation.longitude
          },"clickable":true,"icon":{url : require('@/assets/images/map-marker-destination.png')}}}):_vm._e(),(_vm.shipmentDetail.driverLatitude && _vm.shipmentDetail.driverLongitude)?_c('section',[_c('GmapMarker',{attrs:{"position":{
              lat: _vm.shipmentDetail.driverLatitude,
              lng: _vm.shipmentDetail.driverLongitude
            },"clickable":true,"icon":{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}}})],1):_vm._e(),_vm._l((_vm.shipmentDetail.multiPick),function(multiPick){return _c('section',{key:multiPick.latitude},[_c('GmapMarker',{attrs:{"position":{
              lat: multiPick.latitude,
              lng: multiPick.longitude
            },"clickable":true,"icon":{url : require('@/assets/images/map-marker-multipick.png')}}})],1)}),_vm._l((_vm.shipmentDetail.multiDrop),function(multiDrop){return _c('section',{key:multiDrop.latitude},[_c('GmapMarker',{attrs:{"position":{
              lat: multiDrop.latitude,
              lng: multiDrop.longitude
            },"clickable":true,"icon":{url : require('@/assets/images/map-marker-multidrop.png')}}})],1)})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }