<template>
  <v-container id="order_detail_container" fluid>
    <v-container class="white" fluid>
      <h5 class="mb-4">{{$_strings.order.ORDER_DETAIL}}</h5>
      <CargoDetail
        @fetchOrder="fetchCargoAndDetails"
        :isErrorGetShipmentGroup="isErrorGetShipmentGroup"
        :currentStatus="currentStatus"
        :orderDetails="orderDetails"
        :shipmentDetail="shipmentDetail"
        :itemsModaInfo="itemsModaInfo"
        :isLoadingShipmentGroup="isLoadingShipmentGroup"
      />
      <!-- isLoadingGetListShipment -->
      <v-skeleton-loader
        v-if="isLoadingGetListShipment"
        class="mt-4"
        type="heading, list-item-two-line, heading, list-item-two-line, card"
      ></v-skeleton-loader>
      <v-row v-else-if="isErrorGetListShipment && !isLoadingGetListShipment" class="mt-5">
        <v-col cols="12">
          <h4 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h4>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon @click="fetchShipmentDetail">
            mdi-reload
          </v-icon>
        </v-col>
      </v-row>
      <section v-else>
        <RouteInfoTable
          :itemsRuteInfo="itemsRuteInfo"
        />
        <RouteMultimodaTable
          :orderDetails="orderDetails"
          :itemsModaInfo="itemsModaInfo"
        />
        <h5
          class="mt-4 mb-4"
          v-if="showExpandOrderData.length"
        >
          {{$_strings.order.SHIPPER_INFO}}
        </h5>
        <v-card
          outlined
          class="mt-2"
          v-for="(order, index) in showExpandOrderData"
          :key="index"
        >
          <v-card-actions class="grey lighten-5">
            <v-row justify="space-between">
              <v-col cols="auto">
                <span class="font-weight-bold ml-2">
                  {{shipments[index].shipmentsTitle}}
                </span>
              </v-col>
              <v-col cols="12" sm="auto" class="d-flex justify-space-between">
                <v-chip
                  v-if="orderDetails && (orderDetails.latestStatus.toLowerCase() === 'dibatalkan' || orderDetails.latestStatus.toLowerCase() === 'kedaluwarsa')"
                  color="red"
                  class="white--text"
                  label
                >
                  {{statusOrder(orderDetails.latestStatus)}}
                </v-chip>
                <v-chip
                  v-else
                  color="cyan lighten-5"
                  class="cyan--text"
                  label
                >
                  {{statusOrder(orderDetails.latestStatus)}}
                </v-chip>
                <v-btn
                  icon
                  @click="showDataExpand(index)"
                >
                  <v-icon>{{ showExpandOrderData[index] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- <v-spacer></v-spacer> -->
          </v-card-actions>
          <v-expand-transition>
            <div v-show="showExpandOrderData[index]">
              <v-divider></v-divider>
              <v-card-text>
                <span class="grey--text">
                  {{$_strings.order.CREATE_DATE}} : {{dateFormat(shipments[index].createdDate)}} {{timeFormat(shipments[index].createdDate)}}
                </span>
                <CardItemOrder
                  class="mt-2"
                  :shipment="shipments[index]"
                />
                <v-row
                  v-if="(currentStatus === 'FINISH')
                    && !isCalculated
                    && !isInvoicing
                    && userAccess.canUpdate
                    && (
                      orderDetails && (
                        orderDetails.latestStatus.toLowerCase() !== 'dibatalkan'
                        && orderDetails.latestStatus.toLowerCase() !== 'kedaluwarsa')
                    )
                  "
                  class="mt-2 d-flex justify-space-between">
                  <v-col cols="auto">
                    <h3>{{$_strings.order.TOTAL_BILL}}</h3>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      :loading="isLoadingButtonCalculate"
                      color="primary"
                      @click="setDialogCalculateCost(shipments[index], index)"
                    >
                      {{$_strings.order.CALCULATE}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" md="4">
                    <OrderStatus
                      @fetchHistoryStatus="fetchHistoryStatus"
                      :isLoadingHistoryStatus="isLoadingHistoryStatus"
                      :isErrorGetHistoryStatus="isErrorGetHistoryStatus"
                      :historyStatus="historyStatus"
                    />
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-card>
                      <v-card-text>
                        <Documents
                          :currentStatus="currentStatus"
                          :shipment="shipments[index]"
                          :isLoadingGetDocumentPhoto="isLoadingGetDocumentPhoto"
                          :isErrorGetDocumentPhoto="isErrorGetDocumentPhoto"
                          :isLumpSum="isLumpSum"
                          :documents="documents"
                          :orderDetails="orderDetails"
                          @deleteDocument="deleteDocument"
                          @fetchOrderDocument="fetchOrderDocument"
                        />
                        <v-divider class="mt-6 mb-2"></v-divider>
                        <!-- ONLY FOR LUMPSUM ORDERS -->
                        <DocumentUploadButton
                          v-if="!isLoadingGetDocumentPhoto"
                          ref="documentUploadButton"
                          :currentStatus="currentStatus"
                          :index="index"
                          :isLumpSum="isLumpSum"
                          :isBast="isBast"
                          :formUploadFoto="formUploadFoto"
                          :formFileInput="formFileInput"
                          :shipment="shipments[index]"
                          :orderDetails="orderDetails"
                          @setFileInput="setFileInput"
                        />
                      </v-card-text>
                    </v-card>
                    <!-- ONLY FOR LUMPSUM ORDERS -->
                    <OrderStatusUpdateButton
                      v-if="!isLoadingGetDocumentPhoto"
                      :currentStatus="currentStatus"
                      :index="index"
                      :isLumpSum="isLumpSum"
                      :isBast="isBast"
                      :shipment="shipments[index]"
                      :orderDetails="orderDetails"
                      @setDialogBasp="setDialogBasp"
                      @updateStatusCargoShipment="updateStatusCargoShipment"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </section>
      <Map
        class="my-4"
        :shipmentDetail="shipmentDetail"
      />
      <FooterOrderDetail
        class="mb-2"
        :currentStatus="currentStatus"
        :isBast="isBast"
        :isLumpSum="isLumpSum"
        :orderDetails="orderDetails"
        :isLoadingShipmentGroup="isLoadingShipmentGroup"
        @fetchListCargo="fetchListCargo"
        @updateStatusCargo="updateStatusCargo"
      />
    </v-container>
    <DialogCalculateCost
      ref="dialogCalulateCost"
      :reFetchOrder="reFetchOrder"
      @fetchOrder="fetchCargoAndDetails"
    />
    <DialogChangeDriver
      ref="dialogChangeDriver"
      :itemsModaInfo="itemsModaInfo"
    />
    <DialogBast
      ref="dialogBast"
      @fetchOrderDocument="fetchOrderDocument"
      @fetchListCargo="fetchListCargo"
    />
    <DialogBasp
      ref="dialogBasp"
      @fetchListCargo="fetchListCargo"
    />
    <DialogAccident
      ref="dialogAccident"
      @fetchListCargo="fetchListCargo"
    />
    <DialogImageUploadPreview
      ref="dialogImageUploadPreview"
      @uploadFotoDocument="uploadFotoDocument"
      @resetFormUpload="resetFormUpload"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { dateFormat, timeFormat, statusOrder } from '../../../helper/commonHelpers';
import DialogCalculateCost from '../Dialog/CalculateCost.vue';
import DialogChangeDriver from '../Dialog/ChangeDriver.vue';
import DialogBast from '../Dialog/Bast.vue';
import DialogBasp from '../Dialog/Basp.vue';
import DialogAccident from '../Dialog/Accident.vue';
import DialogImageUploadPreview from '../Dialog/ImageUploadPreview.vue';

// COMPONENTS
import CargoDetail from './CargoDetail.vue';
import RouteInfoTable from './RouteInfoTable.vue';
import RouteMultimodaTable from './RouteMultimodaTable.vue';
import OrderStatus from './OrderStatus.vue';
import Documents from './Documents.vue';
import Map from './Map.vue';
import FooterOrderDetail from './FooterOrderDetail.vue';
import DocumentUploadButton from './DocumentUploadButton.vue';
import OrderStatusUpdateButton from './OrderStatusUpdateButton.vue';
import CardItemOrder from './CardItemOrder.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    DialogCalculateCost,
    DialogChangeDriver,
    DialogBast,
    DialogBasp,
    DialogAccident,
    DialogImageUploadPreview,
    // components
    CargoDetail,
    RouteInfoTable,
    RouteMultimodaTable,
    OrderStatus,
    Documents,
    Map,
    FooterOrderDetail,
    DocumentUploadButton,
    OrderStatusUpdateButton,
    CardItemOrder,
  },
  name: 'order-detail',
  created() {
    source = CancelToken.source();
    this.fetchCargoAndDetails();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      currentStatus: this.$route.query.status || '',
      orderDetails: {}, // COMPLETE DATA
      shipmentDetail: {},
      shipments: [],
      historyStatus: [],
      documents: [],
      showExpandOrderData: [],
      itemsRuteInfo: [],
      itemsModaInfo: [],
      formFileInput: [],
      formUploadFoto: [],
      formShipmentUpdateStatus: {
        datetimeTz: +dayjs().format().split('+')[1].substr(1, 1),
        locationId: null,
        notes: '',
        shipmentId: '',
        status: '',
      },
      targetItemDeleteDocument: {
        shipmentCargoId: '',
        documentId: '',
      },
      reFetchOrder: false,
      showShipmentExpand: true,
      isInvoicing: false,
      isCalculated: false,
      // loaders
      isLoadingShipmentGroup: false,
      isLoadingHistoryStatus: false,
      isLoadingGetListShipment: false,
      isLoadingGetDocumentPhoto: false,
      isLoadingButtonCalculate: false,
      // error
      isErrorGetShipmentGroup: false,
      isErrorGetListShipment: false,
      isErrorGetHistoryStatus: false,
      isErrorGetDocumentPhoto: false,
    };
  },
  computed: {
    isLumpSum() {
      const { lumpsum } = this.$route.params;
      return lumpsum === 'lumpsum';
    },
    isBast() {
      if (!this.orderDetails.id) return false;
      const {
        isCurrentSequenceBASTSubmitted,
        isCurrentSequenceFinish,
        isCurrentSequenceArrived,
        latestStatus,
        currentSequence,
        totalSequence,
        listShipmentBast,
        subOrder,
      } = this.orderDetails;
      const itemsMultiDrop = (subOrder && subOrder.multiDrop && subOrder.multiDrop.length && subOrder.multiDrop[0].itemsMultiDrop) || [];
      const itemsMultiPick = (subOrder && subOrder.multiPick && subOrder.multiPick.length && subOrder.multiPick[0].itemsMultiPick) || [];

      const shipments = (subOrder && subOrder.shipments) || [];

      if (
        (
          (latestStatus.toLowerCase() === 'di_lokasi_tujuan'
            && isCurrentSequenceFinish
          )
        || (
          latestStatus.toLowerCase() === 'ba_penerimaan_diterima_gudang'
          || latestStatus.toLowerCase() === 'ba_penerimaan_kembali_ke_gudang_asal'
          || latestStatus.toLowerCase() === 'ba_penerimaan_ganti_lokasi_tujuan'
          || latestStatus.toLowerCase() === 'barang_diterima_tidak_utuh'
        )
        || (latestStatus.toLowerCase() === 'barang_diterima_utuh'
            && !isCurrentSequenceBASTSubmitted
        )) && !isCurrentSequenceBASTSubmitted
      ) {
        let currentMultidropSequence = 0;
        if (itemsMultiDrop.length) {
          currentMultidropSequence = currentSequence - 1;
        }
        if (itemsMultiPick.length) {
          currentMultidropSequence = currentSequence - itemsMultiPick.length - 1;
        }
        if (isCurrentSequenceArrived && isCurrentSequenceFinish && itemsMultiDrop.length && itemsMultiDrop[currentMultidropSequence]) {
          if (listShipmentBast !== null && currentSequence !== totalSequence && shipments.length > 1) return false;
        }
        if (isCurrentSequenceArrived && isCurrentSequenceFinish && listShipmentBast && currentSequence !== totalSequence) return false;
        return true;
      }
      return false;
    },
  },
  watch: {
    reFetchOrder(newVal) {
      if (newVal) {
        this.fetchCargoAndDetails();
        this.reFetchOrder = false;
      }
    },
    '$route.query.status': function updateCurrentStatus(newVal) {
      this.currentStatus = newVal;
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    async updateStatusCargo() {
      const { id, subOrder: { shipments } } = this.orderDetails;
      this.updateFormShipment(shipments[0]);
      try {
        this.$root.$loading.show();
        await this.$_services.order.updateCargoOrderStatus(id, this.formShipmentUpdateStatus, source);
        this.fetchListCargo();
      } finally {
        this.$root.$loading.hide();
      }
    },
    checkDocumentUpload(shipment) {
      const {
        latestStatus,
      } = this.orderDetails;
      let valid = true;
      if (!this.historyStatus.length) {
        this.$dialog.notify.error('Lokasi terakhir tidak ditemukan!');
        return false;
      }
      const _latestStatus = latestStatus.replace(/_/g, ' ').toLowerCase();
      const { statusDateTime } = this.historyStatus.find((x) => x.status.replace(/-/g, '').replace(/\s\s/g, ' ').toLowerCase() === _latestStatus);
      const lastStatusDateTime = dayjs(statusDateTime).valueOf();
      const filterDocument = this.documents.filter((x) => x.shipmentId === shipment.shipmentsId && x.createdAt > lastStatusDateTime);
      const FOTO_SURAT_JALAN = filterDocument.filter((x) => x.documentType === 'FOTO_SURAT_JALAN');
      const FOTO_MUAT_BARANG = filterDocument.filter((x) => x.documentType === 'FOTO_MUAT_BARANG');
      const FOTO_BONGKAR_BARANG = filterDocument.filter((x) => x.documentType === 'FOTO_BONGKAR_BARANG');
      // MINIMUN UPLOAD 1 FOTO PER LOCATION (DI_LOKASI_PENJEMPUTAN / DI_LOKASI_TUJUAN)
      if (latestStatus === 'DI_LOKASI_PENJEMPUTAN') {
        valid = false;
        if (
          FOTO_SURAT_JALAN.length >= 1
        && FOTO_MUAT_BARANG.length >= 1
        ) valid = true;
      }
      if (latestStatus === 'DI_LOKASI_TUJUAN') {
        valid = false;
        if (FOTO_BONGKAR_BARANG.length >= 1) valid = true;
      }
      return valid;
    },
    async updateStatusCargoShipment(index, shipment, isBast) {
      // const {
      //   latestStatus,
      // } = this.orderDetails;
      this.updateFormShipment(shipment);
      // const { id: shipmentId } = shipment;
      if (isBast) {
        // if (!this.isUploadedDocument('FOTO_BONGKAR_BARANG', shipmentId)) {
        //   this.formFileInput[index].documentation.isRequired = true;
        //   this.$dialog.notify.error('Silahkan Upload Dokumentasi Terlebih Dahulu');
        //   return;
        // }
        this.$refs.dialogBast.dialog = true;
        this.$refs.dialogBast.item = {
          ...this.orderDetails,
          ...shipment,
          ...this.formShipmentUpdateStatus,
        };
        return;
      }

      // CHECK DOCUMENT REQUIRE
      // if (!this.checkDocumentUpload(shipment)) {
      //   this.formFileInput[index].travelDocument.isRequired = true;
      //   if (latestStatus === 'DI_LOKASI_PENJEMPUTAN') {
      //     this.$dialog.notify.error('Harap upload foto surat jalan dan dokumentasi');
      //   }
      //   if (latestStatus === 'DI_LOKASI_TUJUAN') {
      //     this.$dialog.notify.error('Harap upload dokumentasi');
      //   }
      //   return;
      // }
      const {
        id,
        isCurrentSequenceFinish,
        isCurrentSequenceBASTSubmitted,
        isCurrentSequenceArrived,
        currentSequence,
        totalSequence,
      } = this.orderDetails;
      if (isCurrentSequenceFinish
      && isCurrentSequenceArrived
      && (currentSequence === totalSequence)
      && !isCurrentSequenceBASTSubmitted) {
        this.$refs.dialogBast.dialog = true;
        this.$refs.dialogBast.item = {
          ...this.orderDetails,
          ...shipment,
        };
        return;
      }
      try {
        this.$root.$loading.show();
        await this.$_services.order.updateShipmentOrderStatus(id, this.formShipmentUpdateStatus, source);
        this.fetchListCargo();
      } finally {
        this.$root.$loading.hide();
      }
    },
    async getlistCargo(status, filters) {
      const { cargoTitle } = this.$route.query;
      return new Promise((resolve, reject) => {
        this.$_services.order.orderList(filters, status, cargoTitle, source).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    async fetchCargoAndDetails() {
      const {
        pickupDateTo,
        pickupDateFrom,
        status,
      } = this.$route.query;
      const { id } = this.$route.params;
      const filters = {
        page: 0,
        size: 10,
        pickupDateFrom: dayjs(pickupDateFrom).format('YYYY-MM-DD'),
        pickupDateTo: dayjs(pickupDateTo).format('YYYY-MM-DD'),
        sort: 'estimateArrival,ASC',
      };
      try {
        this.isErrorGetShipmentGroup = false;
        this.isLoadingShipmentGroup = true;
        this.isLoadingGetListShipment = true;
        let resCargo = await this.getlistCargo(status, filters);
        if (resCargo.data.contents) {
          let cargoShipment = resCargo.data.contents.find((cargo) => cargo.id === +id);
          if (!cargoShipment) {
            const replaceStatus = status === 'FINISH' ? 'ON_PROCESS' : 'FINISH';
            resCargo = await this.getlistCargo(replaceStatus, filters);
            cargoShipment = resCargo.data.contents.find((cargo) => cargo.id === +id);
          }
          try {
            this.orderDetails = {
              ...cargoShipment,
            };
            const resShipmentDetail = await this.fetchShipmentDetail();
            if (resShipmentDetail.data.shipments) {
              const shipments = resShipmentDetail.data.shipments.map((i) => ({ ...i, type: 'Sub Order' }));
              const { originLocationName, destinationLocationName, estimateArrival } = cargoShipment;
              const multiPick = [{ originLocationName, estimateArrival, itemsMultiPick: resShipmentDetail.data.multiPick }];
              const multiDrop = [{ destinationLocationName, estimateArrival, itemsMultiDrop: resShipmentDetail.data.multiDrop }];
              const subOrder = {
                shipments, multiPick, multiDrop,
              };
              this.orderDetails = {
                ...this.orderDetails,
                subOrder,
              };
              this.setExpandShipmentsAndSetFormUploadFoto(resShipmentDetail.data.shipments.length);
            }
          } catch {
            // this.isErrorGetShipmentGroup = true;
          } finally {
            this.isLoadingGetListShipment = false;
          }
        }
        this.fetchHistoryStatus();
        this.fetchOrderDocument();
      } finally {
        this.isLoadingShipmentGroup = false;
      }
    },
    async fetchListCargo() {
      const {
        pickupDate,
        originLocationId,
        destinationLocationId,
        id,
      } = this.orderDetails;
      const filters = {
        pickupDateFrom: dayjs(pickupDate).format('YYYY-MM-DD'),
        pickupDateTo: dayjs(pickupDate).format('YYYY-MM-DD'),
        originLocationId,
        destinationLocationId,
        sort: 'estimateArrival,ASC',
      };
      try {
        this.isLoadingShipmentGroup = true;
        let res = await this.getlistCargo('ON_PROCESS', filters);
        if (res.data.contents) {
          let cargoShipment = res.data.contents.find((cargo) => cargo.id === id);
          if (!cargoShipment) {
            res = await this.getlistCargo('FINISH', filters);
            cargoShipment = res.data.contents.find((cargo) => cargo.id === id);
            if (cargoShipment) {
              this.$router.replace({
                params: {
                  ...this.$route.params,
                },
                query: {
                  ...this.$route.query,
                  status: 'FINISH',
                },
              });
            }
          }
          if (cargoShipment) {
            const oldValueOrderDetails = JSON.parse(JSON.stringify(this.orderDetails));
            this.orderDetails = { ...oldValueOrderDetails, ...cargoShipment };
            this.fetchHistoryStatus();
          }
        }
      } finally {
        this.isLoadingShipmentGroup = false;
      }
    },
    async fetchShipmentDetail() {
      const { id: shipmentCargoId } = this.$route.params;
      this.isLoadingGetListShipment = true;
      this.isErrorGetListShipment = false;
      return new Promise((resolve, reject) => {
        this.$_services.order.shipmentDetail(shipmentCargoId, source).then((result) => {
          this.shipmentDetail = result.data;
          this.shipments = result.data.shipments;
          this.isInvoicing = result.data.isInvoicing;
          this.isCalculated = result.data.isCalculated;
          const {
            containerNo,
            flightCruiseNo,
            flightCruiseName,
            estimateDeparture,
            companyDriverName,
            companyDriverPhone,
            companyTransportNo,
            transportTypeName,
            multiPick,
            multiDrop,
            estimateArrival,
            originLocation,
            destinationLocation,
          } = result.data;
          this.itemsModaInfo = [{
            containerNo,
            flightCruiseNo,
            flightCruiseName,
            estimateDeparture,
            companyDriverName,
            companyDriverPhone,
            companyTransportNo,
            transportTypeName,
          }];
          const { locationName: originLocationName } = originLocation;
          const { locationName: destinationLocationName } = destinationLocation;
          this.itemsRuteInfo = [
            {
              originLocationName,
              destinationLocationName,
              itemsMultiPick: multiPick,
              itemsMultiDrop: multiDrop,
              estimateArrival,
            },
          ];
          const _multiPick = [{ originLocationName, estimateArrival, itemsMultiPick: multiPick }];
          const _multiDrop = [{ destinationLocationName, estimateArrival, itemsMultiDrop: multiDrop }];
          const subOrder = {
            shipments: result.data.shipments,
            multiPick: _multiPick,
            multiDrop: _multiDrop,
          };
          this.orderDetails.subOrder = subOrder;
          resolve(result);
        }).catch((err) => {
          this.isErrorGetListShipment = true;
          reject(err);
        }).finally(() => {
          this.isLoadingGetListShipment = false;
        });
      });
    },
    async fetchHistoryStatus() {
      const { id: shipmentCargoId } = this.$route.params;
      try {
        this.isLoadingHistoryStatus = true;
        this.isErrorGetHistoryStatus = false;
        const result = await this.$_services.order.historyStatus(shipmentCargoId, source);
        this.historyStatus = result.data;
      } catch {
        this.isErrorGetHistoryStatus = true;
      } finally {
        this.isLoadingHistoryStatus = false;
      }
    },
    async fetchOrderDocument() {
      const { id: shipmentCargoId } = this.$route.params;
      try {
        this.isLoadingGetDocumentPhoto = true;
        this.isErrorGetDocumentPhoto = false;
        const result = await this.$_services.order.orderDocument(shipmentCargoId, source);
        if (result.data.contents) {
          this.documents = result.data.contents.map((doc) => ({
            ...doc,
            customeName: `${doc.documentType}-${String(Math.random()).split('.')[1]}`,
          }));
        }
      } catch {
        this.isErrorGetDocumentPhoto = true;
      } finally {
        this.isLoadingGetDocumentPhoto = false;
      }
    },
    async deleteDocument(shipmentCargoId, documentId) {
      try {
        this.isLoadingGetDocumentPhoto = true;
        await this.$_services.podDocument.deletePod(shipmentCargoId, documentId);
        this.fetchOrderDocument();
      } finally {
        this.isLoadingGetDocumentPhoto = false;
      }
    },
    async uploadFotoDocument(index, shipment, type, encodedImage) {
      this.formUploadFoto[index][type].encodedImage = encodedImage;
      const dataFoto = this.formUploadFoto[index][type];
      const { id } = shipment;
      this.updateFormShipment(shipment);
      const formUpload = {
        locationId: this.formShipmentUpdateStatus.locationId,
        documentType: dataFoto.documentType,
        encodedImages: [
          {
            encodedImage: dataFoto.encodedImage,
            fileType: 'IMAGE',
          },
        ],
        shipmentId: id,
      };
      const { id: shipmentCargoId } = this.$route.params;
      try {
        dataFoto.isLoading = true;
        await this.$_services.order.uploadDocumentPod(shipmentCargoId, formUpload, source);
        this.fetchOrderDocument();
        if (this.$refs.documentUploadButton[index].$refs[`${type}${index}`].reset) this.$refs.documentUploadButton[index].$refs[`${type}${index}`].reset();
        dataFoto.encodedImage = '';
      } catch {
        if (this.$refs.documentUploadButton[index].$refs[`${type}${index}`].reset) this.$refs.documentUploadButton[index].$refs[`${type}${index}`].reset();
        dataFoto.encodedImage = '';
      } finally {
        dataFoto.isLoading = false;
      }
    },
    setExpandShipmentsAndSetFormUploadFoto(length) {
      this.showExpandOrderData = [];
      const { latestStatus } = this.orderDetails;
      let typeDocumentation = '';
      if (latestStatus.toLowerCase() === 'penjemputan' || latestStatus.toLowerCase() === 'di_lokasi_penjemputan') typeDocumentation = 'FOTO_MUAT_BARANG';
      if (latestStatus.toLowerCase() === 'pengiriman' || latestStatus.toLowerCase() === 'di_lokasi_tujuan') typeDocumentation = 'FOTO_BONGKAR_BARANG';
      this.formUploadFoto = [];
      this.formFileInput = [];
      for (let i = 0; i < length; i += 1) {
        this.showExpandOrderData.push(true);
        this.formFileInput.push({
          travelDocument: {
            encodedImage: null,
            isRequired: false,
          },
          documentation: {
            encodedImage: null,
            isRequired: false,
          },
          deliveryOrder: {
            encodedImage: null,
            isRequired: false,
          },
        });
        this.formUploadFoto.push({
          travelDocument: {
            documentType: 'FOTO_SURAT_JALAN',
            encodedImage: '',
            isLoading: false,
          },
          documentation: {
            documentType: typeDocumentation,
            encodedImage: '',
            isLoading: false,
          },
          deliveryOrder: {
            documentType: 'SURAT_PERINTAH_KIRIMAN',
            encodedImage: '',
            isLoading: false,
          },
        });
      }
    },
    isUploadedDocument(documentType, shipmentId) {
      let uploaded = false;
      if (this.historyStatus.length && this.documents.length) {
        const {
          latestStatus,
        } = this.orderDetails;
        const _latestStatus = latestStatus.replace(/_/g, ' ').toLowerCase();
        const { statusDateTime } = this.historyStatus.find((x) => x.status.replace(/-/g, '').replace(/\s\s/g, ' ').toLowerCase() === _latestStatus);
        const latestStatusDate = dayjs(statusDateTime).unix();
        this.documents.forEach((doc) => {
          const formatDateUpdatedAt = dayjs(doc.updatedAt).unix();
          if (
            doc.documentType === documentType
            && doc.shipmentId === shipmentId
            && (
              formatDateUpdatedAt > latestStatusDate
            )
          ) {
            uploaded = true;
          }
        });
      }
      return uploaded;
    },
    statusOrder(status) { return statusOrder(status); },
    showDataExpand(index) {
      this.showExpandOrderData.splice(index, 1, !this.showExpandOrderData[index]);
    },
    setDialogCalculateCost(item, index) {
      this.$refs.dialogCalulateCost.orderSelected = { ...item, sequence: index + 1 };
      this.$refs.dialogCalulateCost.dialog = true;
    },
    setDialogAccident() {
      const { id: shipmentCargoId } = this.$route.params;
      this.$refs.dialogAccident.shipmentCargoId = shipmentCargoId;
      this.$refs.dialogAccident.dialog = true;
    },
    setDialogBasp(shipment) {
      this.updateFormShipment(shipment);
      this.$refs.dialogBasp.item = {
        ...this.orderDetails,
        ...this.formShipmentUpdateStatus,
        ...shipment,
      };
      this.$refs.dialogBasp.dialog = true;
    },
    setDialog(dialogBy, item = {}, sequence = '') {
      if (item && (sequence || sequence === 0)) this.order = { sequence: sequence + 1, ...item };
      this[dialogBy] = !this[dialogBy];
    },
    setDialogShipmentDocumentAdditional(shipment) {
      const { id } = this.$route.params;
      this.$refs.dialogShipmentDocumentAdditional.formShipmentDocumentAdditional.shipmentsId = id;
      this.$refs.dialogShipmentDocumentAdditional.formShipmentDocumentAdditional.shipmentDetailId = shipment.id;
      this.$refs.dialogShipmentDocumentAdditional.dialog = true;
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'svg'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    resetFormUpload(type, index) {
      if (this.$refs.documentUploadButton[index].$refs[`${type}${index}`]) {
        this.$refs.documentUploadButton[index].$refs[`${type}${index}`].reset();
      }
      this.formFileInput[index][type].encodedImage = null;
    },
    setFileInput(file, type, index) {
      const valid = this.fileUploadValidation(file);
      const { latestStatus } = this.orderDetails;
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = (e) => {
        let typeDocumentation = '';
        if (latestStatus.toLowerCase() === 'penjemputan' || latestStatus.toLowerCase() === 'di_lokasi_penjemputan') typeDocumentation = 'FOTO_MUAT_BARANG';
        if (
          latestStatus.toLowerCase() === 'pengiriman'
          || latestStatus.toLowerCase() === 'di_lokasi_tujuan'
          || latestStatus.toLowerCase() === 'ba_penerimaan_diterima_gudang'
          || latestStatus.toLowerCase() === 'ba_penerimaan_kembali_ke_gudang_asal'
          || latestStatus.toLowerCase() === 'ba_penerimaan_ganti_lokasi_tujuan'
          || latestStatus.toLowerCase() === 'barang_diterima_tidak_utuh'
          || latestStatus.toLowerCase() === 'barang_diterima_utuh'
        ) typeDocumentation = 'FOTO_BONGKAR_BARANG';
        if (type === 'documentation') {
          this.formUploadFoto[index][type].documentType = typeDocumentation;
        }
        // DIALOG IMAGE UPLOAD PREVIEW
        this.$refs.dialogImageUploadPreview.formUpload = {
          index,
          shipment: this.shipments[index],
          type,
        };
        this.$refs.dialogImageUploadPreview.encodedImage = e.target.result;
        this.$refs.dialogImageUploadPreview.dialog = true;
      };
    },
    updateFormShipment(shipment) {
      const {
        currentSequence,
        isCurrentSequenceArrived,
        isCurrentSequenceFinish,
        totalSequence,
        originLocationId,
        originLocationName,
        destinationLocationId,
        destinationLocationName,
        subOrder,
        isCurrentSequenceBASTSubmitted,
        listShipmentBast,
      } = this.orderDetails;
      const itemsMultiPick = (subOrder && subOrder.multiPick && subOrder.multiPick.length && subOrder.multiPick[0].itemsMultiPick) || [];
      const itemsMultiDrop = (subOrder && subOrder.multiDrop && subOrder.multiDrop.length && subOrder.multiDrop[0].itemsMultiDrop) || [];
      if (currentSequence === 0) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: originLocationId,
          notes: originLocationName,
          shipmentId: shipment.id,
          status: 'PENJEMPUTAN',
        };
        return;
      }
      if (currentSequence === 1 && (!isCurrentSequenceArrived || !isCurrentSequenceFinish)) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: originLocationId,
          notes: originLocationName,
          shipmentId: shipment.id,
          status: 'DI_LOKASI_PENJEMPUTAN',
        };
        return;
      }
      if (isCurrentSequenceArrived && isCurrentSequenceFinish && itemsMultiPick.length && itemsMultiPick[currentSequence - 1]) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: itemsMultiPick[currentSequence - 1].locationId,
          notes: itemsMultiPick[currentSequence - 1].locationName,
          shipmentId: shipment.id,
          status: 'PENJEMPUTAN',
        };
        return;
      }
      if ((!isCurrentSequenceArrived || isCurrentSequenceArrived) && !isCurrentSequenceFinish && itemsMultiPick.length && itemsMultiPick[currentSequence - 2]) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: itemsMultiPick[currentSequence - 2].locationId,
          notes: itemsMultiPick[currentSequence - 2].locationName,
          shipmentId: shipment.id,
          status: 'DI_LOKASI_PENJEMPUTAN',
        };
        return;
      }
      let currentMultidropSequence = 0;
      if (itemsMultiDrop.length) {
        currentMultidropSequence = currentSequence - 1;
      }
      if (itemsMultiPick.length) {
        currentMultidropSequence = currentSequence - itemsMultiPick.length - 1;
      }
      if (isCurrentSequenceArrived && isCurrentSequenceFinish && itemsMultiDrop.length && itemsMultiDrop[currentMultidropSequence]) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: itemsMultiDrop[currentMultidropSequence].locationId,
          notes: itemsMultiDrop[currentMultidropSequence].locationName,
          shipmentId: shipment.id,
          status: 'PENGIRIMAN',
        };
        return;
      }
      if (itemsMultiDrop.length) {
        currentMultidropSequence = currentSequence - 2;
      }
      if (itemsMultiPick.length) {
        currentMultidropSequence = currentSequence - itemsMultiPick.length - 2;
      }

      const isShipmentTitleMissing = listShipmentBast && itemsMultiDrop.length && itemsMultiDrop[currentMultidropSequence] && !listShipmentBast.includes(itemsMultiDrop[currentMultidropSequence].shipmentTitle);

      if ((!isCurrentSequenceArrived || isCurrentSequenceArrived) && (!isCurrentSequenceFinish || isCurrentSequenceFinish) && itemsMultiDrop.length && itemsMultiDrop[currentMultidropSequence] && !isCurrentSequenceBASTSubmitted && (isShipmentTitleMissing || !listShipmentBast)) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: itemsMultiDrop[currentMultidropSequence].locationId,
          notes: itemsMultiDrop[currentMultidropSequence].locationName,
          shipmentId: shipment.id,
          status: 'DI_LOKASI_TUJUAN',
        };
        return;
      }
      if (currentSequence === totalSequence - 1 && isCurrentSequenceArrived && isCurrentSequenceFinish && (!listShipmentBast || !isShipmentTitleMissing)) {
        this.formShipmentUpdateStatus = {
          ...this.formShipmentUpdateStatus,
          locationId: destinationLocationId,
          notes: destinationLocationName,
          shipmentId: shipment.id,
          status: 'PENGIRIMAN',
        };
        return;
      }
      this.formShipmentUpdateStatus = {
        ...this.formShipmentUpdateStatus,
        locationId: destinationLocationId,
        notes: destinationLocationName,
        shipmentId: shipment.id,
        status: 'DI_LOKASI_TUJUAN',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table-header {
    tr {
      .amber.accent-3 {
        background-color: #f1c231 !important;
      }
      th:nth-child(1) {
        border-top-left-radius: 5px;
      }
      th:nth-last-child(1) {
        border-top-right-radius: 5px;
      }
    }
  }
  .v-input__control {
    margin-top: 10px !important;
  }
  .file_input {
    border-radius: 20px;
    width: 80%;
    .v-file-input__text--placeholder {
      display: flex;
      justify-content: center;
      font-size: 0.75rem;
    }
  }
}
.img-box{
  margin-bottom: 2px;
  display: block;
  width: 100%;
  border: 1px solid rgb(189, 183, 183);
  height: 120px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
