var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h5',{staticClass:"mt-4 mb-4"},[_vm._v(_vm._s(_vm.$_strings.order.RUTE_INFO))]),_c('v-data-table',{staticClass:"elevation-1 rounded",attrs:{"headers":_vm.headerRuteInfo,"calculate-widths":"","hide-default-footer":"","items":_vm.itemsRuteInfo},scopedSlots:_vm._u([{key:"item.multiPick",fn:function(ref){
var item = ref.item;
return [(item.itemsMultiPick.length === 0)?_c('p',[_vm._v(" - ")]):_vm._e(),_vm._l((item.itemsMultiPick),function(itemMultiPick,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(itemMultiPick.locationName)+" ")])})]}},{key:"item.multiDrop",fn:function(ref){
var item = ref.item;
return [(item.itemsMultiDrop.length === 0)?_c('p',[_vm._v(" - ")]):_vm._e(),_vm._l((item.itemsMultiDrop),function(itemMultiDrop,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(itemMultiDrop.locationName)+" ")])})]}},{key:"item.estimateArrival",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.dateFormat(item.estimateArrival)))]),(item.estimateArrival)?_c('p',[_vm._v(_vm._s(_vm.timeFormat(item.estimateArrival)))]):_vm._e()]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('pre',[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.note ? item.note : '-'))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }