<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
    persistent
  >
    <v-card class="overflow-hidden">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-toolbar-items>
            <v-btn
              icon
              color="black"
              dark
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
      <v-card-text class="justify-center d-flex">
        <v-img
          contain
          max-height="600"
          max-width="600"
          :src="encodedImage"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card-text>
      <v-card-actions
        class="d-flex justify-center"
      >
        <v-btn
          color="primary"
          @click="uploadFotoDocument"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'image-upload-preview',
  data() {
    return {
      dialog: false,
      encodedImage: null,
      formUpload: {}, // FROM PARENT
    };
  },
  methods: {
    uploadFotoDocument() {
      const { type, shipment, index } = this.formUpload;
      this.$emit('uploadFotoDocument', index, shipment, type, this.encodedImage);
      this.encodedImage = null;
      this.formUpload = {};
      this.dialog = false;
    },
    closeDialog() {
      const { type, index } = this.formUpload;
      this.$emit('resetFormUpload', type, index);
      this.encodedImage = null;
      this.formUpload = {};
      this.dialog = false;
    },
  },
};
</script>
