<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col class="pb-0" cols="12" sm="6">
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.SHIPPER_NAME}}</p>
          <v-text-field
            outlined
            hide-details
            dense
            class="caption"
            disabled
            :value="shipment.companyName">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.NAME_SENDER}}</p>
          <v-text-field
            outlined
            hide-details
            dense
            class="caption"
            disabled
            :value="shipment.originSenderName || '-'">
          </v-text-field>
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.CATEGORY}}</p>
          <v-text-field
            outlined
            hide-details
            dense
            class="caption"
            disabled
            :value="shipment.categoryName">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.PHONE_NUMBER_SENDER}}</p>
          <v-text-field
            outlined
            hide-details
            dense
            class="caption"
            disabled
            :value="shipment.originSenderPhone || '-'">
          </v-text-field>
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.SUB_CATEGORY}}</p>
          <v-text-field
            outlined
            hide-details
            dense
            class="caption"
            disabled
            :value="shipment.subCategoryName">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.GOODS_NOTE}}</p>
          <pre class="overflow-auto"><p class="ma-0">{{shipment.goodsNote || '-'}}</p></pre>
        </v-col>
        <v-col cols="12">
          <p class="mb-0 mt-2 grey--text">{{$_strings.order.ADDITIONAL_NOTE}}</p>
          <pre class="overflow-auto"><p class="ma-0">{{shipment.additionalNote || '-'}}</p></pre>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
