<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
      scrollable
    >
      <v-card :loading="isLoading">
        <v-card-title class="caption">
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title class="pb-0 body-2 d-flex justify-center">
          <h4 class="text-center">{{$_strings.order.REPORT_DELIVERY_PROBLEMS}}</h4>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="form" class="custom__card" lazy-validation>
            <v-row>
              <v-col class="pb-0" cols="12" sm="4">
                <span>{{$_strings.order.NO_CARGO}}</span>
              </v-col>
              <v-col class="pt-0 pt-sm-3" cols="12" sm="4">
                <span>{{$route.query.cargoTitle}}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12" sm="4">
                <span>{{$_strings.order.ORDER_NUMBER}}</span>
              </v-col>
              <v-col class="pt-0 pt-sm-3" cols="12" sm="8">
                <p v-if="shipmentsTitle.length === 1"> {{shipmentsTitle[0]}} </p>
                <p v-else class="mb-0" v-for="(shipment, index) in shipmentsTitle" :key="index">
                  {{index + 1}}. {{shipment}}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12">
                <h4>{{$_strings.order.LATE_CAUSE}}</h4>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-autocomplete
                  :loading="isLoadingListReason"
                  v-model="tempReason"
                  class="caption"
                  :items="itemsReason"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.LATE_CAUSE)]"
                  outlined
                  dense
                  item-text="reason"
                  item-value="id"
                  @change="handleReason"
                  @input.native="searchReason"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0" cols="12">
                <h4>{{$_strings.order.DESCRIPTION}}</h4>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-textarea
                  v-model="form.accidentReasonNotes"
                  no-resize
                  outlined
                  class="caption"
                  dense
                  clearable
                  rows="5"
                  :counter="255"
                  :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.EXPLANATION_OF_REASON)]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0" cols="12">
                <h4>{{$_strings.order.ADDRESS_LOCATION}}</h4>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <vuetify-google-autocomplete
                  id="map"
                  clearable
                  v-model="form.address"
                  @placechanged="placePickedHandler"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.ADDRESS_LOCATION)]"
                  outlined
                  dense
                  class="caption"
                  types=""
                >
                </vuetify-google-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0" cols="12">
                <h4>{{$_strings.order.POSITION}}</h4>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <GmapMap
                  @center_changed="updatePosition"
                  :center="mapCenter"
                  :zoom="15"
                  map-type-id="terrain"
                  class="w-100 h5"
                  :options="{
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    disableDefaultUi: true
                  }"
                >
                  <GmapMarker
                    :position="{
                      lat: form.latitude,
                      lng: form.longitude
                    }"
                    :clickable="true"
                    :icon="{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}"
                  />
                </GmapMap>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h4>{{$_strings.order.PROOF_OF_PROBLEM}}</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <div class="img-box">
                      <div v-if="photo1" class="box-mdi-close">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              @click="removeImage('fileInputPhoto1', 'photo1')"
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span>Hapus Foto</span>
                        </v-tooltip>
                      </div>
                      <img v-if="photo1" :src="photo1" alt="">
                    </div>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-file-input
                      :clearable="false"
                      show-size
                      ref="file_upload"
                      @change="($event) => setFileInput($event, 'photo1')"
                      v-model="fileInputPhoto1"
                      :rules="photo2 ? [] : [v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.PROOF_OF_PROBLEM)]"
                      :disabled="isLoading"
                      label="Pilih Foto"
                      type="file"
                      accept="image/*"
                      outlined
                      dense
                      prepend-icon=""
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          style="w-50"
                          small
                          label
                          color="primary"
                        >
                          {{ text.length > 20 ? text.substr(0, 20) : text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <div class="img-box">
                      <div v-if="photo2" class="box-mdi-close">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              @click="removeImage('fileInputPhoto2', 'photo2')"
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                          <span>Hapus Foto</span>
                        </v-tooltip>
                      </div>
                      <img v-if="photo2" :src="photo2" alt="">
                    </div>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-file-input
                      :clearable="false"
                      show-size
                      ref="file_upload"
                      @change="($event) => setFileInput($event, 'photo2')"
                      v-model="fileInputPhoto2"
                      :disabled="isLoading"
                      label="Pilih Foto"
                      type="file"
                      accept="image/*"
                      outlined
                      dense
                      prepend-icon=""
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          style="w-50"
                          small
                          label
                          color="primary"
                        >
                          {{ text.length > 20 ? text.substr(0, 20) : text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click="submit"
            :disabled="isLoading"
          >
            {{$_strings.order.REPORT_THE_PROBLEM}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
import { rulesNotes } from '../../../helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();
const date = new Date();

let searchReason;

export default {
  props: {
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoadingListReason: false,
      isLoading: false,
      dialog: false,
      shipmentCargoId: '',
      shipmentsTitle: '',
      itemsReason: [],
      tempReason: '',
      fileInputPhoto1: null,
      fileInputPhoto2: null,
      photo1: '',
      photo2: '',
      form: {
        accidentReason: '',
        address: '',
        isNeedConfirm: '',
        encodedFile: [],
        accidentReasonNotes: '',
        imageNote: '',
        latitude: 0.0,
        longitude: 0.0,
        datetimeTz: date.toString().split('+')[1].substr(1, 1),
      },
      mapCenter: {
        lat: -6.1753917,
        lng: 106.82715329999996,
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
        this.tempReason = '';
        this.fileInputPhoto1 = null;
        this.fileInputPhoto2 = null;
        this.photo1 = '';
        this.photo2 = '';
        this.form = {
          accidentReason: '',
          address: '',
          encodedFile: [],
          accidentReasonNotes: '',
          imageNote: '',
          latitude: 0.0,
          longitude: 0.0,
          datetimeTz: date.toString().split('+')[1].substr(1, 1),
        };
        return;
      }
      const { subOrder: { shipments } } = this.orderDetails;
      const textShipment = [];
      shipments.forEach((shipment) => {
        textShipment.push(shipment.shipmentsTitle);
      });
      this.shipmentsTitle = textShipment;
      this.getListReason();
      source = CancelToken.source();
    },
  },
  methods: {
    rulesNotes,
    placePickedHandler(place, placeDetail) {
      this.form = {
        ...this.form,
        latitude: placeDetail.geometry.location.lat(),
        longitude: placeDetail.geometry.location.lng(),
      };
      this.mapCenter = {
        lat: placeDetail.geometry.location.lat(),
        lng: placeDetail.geometry.location.lng(),
      };
    },
    updatePosition(event) {
      this.form = {
        ...this.form,
        latitude: event.lat(),
        longitude: event.lng(),
      };
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    searchReason(event) {
      clearTimeout(searchReason);
      searchReason = setTimeout(() => {
        this.getListReason(event);
      }, 500);
    },
    async getListReason(event) {
      const filters = {
        page: 0,
        size: 20,
        type: 'qIn(accident)',
        sort: 'name,desc',
        isActive: 'qistrue',
      };
      if (event) {
        filters.name = `qLike(${event.target.value})`;
      }
      this.isLoadingListReason = true;
      try {
        const res = await this.$_services.order.baspReason(filters);
        this.itemsReason = res.data.contents;
      } finally {
        this.isLoadingListReason = false;
      }
    },
    handleReason(idBaspReason) {
      const findReason = this.itemsReason.find((item) => item.id === idBaspReason);
      this.form.accidentReason = findReason.reason;
      this.form.isNeedConfirm = findReason.needConfirm;
    },
    setFileInput(file, type) {
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this[type] = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage(fileInput, type) {
      this[fileInput] = null;
      this[type] = null;
    },
    async submit() {
      this.$refs.form.validate();
      this.form.encodedFile = [];
      if (this.photo1) this.form.encodedFile.push(this.photo1);
      if (this.photo2) this.form.encodedFile.push(this.photo2);
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          this.isLoading = true;
          await this.$_services.order.reportOrderAccident(this.shipmentCargoId, this.form, source);
          this.$dialog.notify.success('Berhasil mengirim laporan');
          this.$emit('fetchListCargo');
          this.dialog = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom__card {
  border: 1px solid rgb(110, 110, 110);
  border-radius: 5px;
  padding: 10px 30px;
}
.img-box{
  margin-bottom: 2px;
  display: block;
  width: 100%;
  border: 1px solid rgb(189, 183, 183);
  height: 120px;
  position: relative;
  img {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
  .box-mdi-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
  }
}
</style>
