<template>
  <v-container fluid>
    <h4>{{shippingProblem.shippingProblem}}</h4>
    <v-sheet class="white">
      <v-container fluid>
        <p class="ma-0">{{$route.query.shipmentGroupTitle}}</p>
      </v-container>
      <v-divider></v-divider>
      <v-divider></v-divider>
    </v-sheet>
    <v-sheet>
      <v-container fluid>
        <v-row
          justify="center"
          dense
          align="center"
          class="mb-4"
          v-if="!$vuetify.breakpoint.xs"
        >
          <v-col cols="12" sm="6">
            <span class="grey--text">{{$_strings.shippingProblem.problemDetail}}</span>
          </v-col>
          <v-col cols="12" sm="6">
            <span class="grey--text" v-if="fromPage === 'Completed'">{{$_strings.shippingProblem.solution}}</span>
          </v-col>
        </v-row>
        <v-card
          class="mx-auto pa-4 mb-4"
          outlined
        >
          <v-row>
            <v-col cols="12" :sm="fromPage === 'Completed' ? '3' : '6'">
              <p class="grey--text mb-0">Masalah</p>
              <p class="black--text mt-0">{{shippingProblem.accidentReason}}</p>
              <p class="grey--text mb-0">Dilaporkan oleh</p>
              <p class="black--text mt-0">{{shippingProblem.reportedBy}}</p>
              <p class="grey--text mb-0">{{$_strings.shippingProblem.address}}</p>
              <p class="black--text mt-0">{{shippingProblem.address || '-'}}</p>
            </v-col>
            <v-col cols="12" :sm="fromPage === 'Completed' ? '3' : '6'">
              <p class="grey--text mb-0">Pada</p>
              <p class="black--text mt-0">{{dateFormat(shippingProblem.reportedDate)}}</p>
              <p class="grey--text mb-0">Catatan</p>
              <p class="black--text mt-0" v-html="replaceNoteToHtml(shippingProblem.accidentReasonNotes)"></p>
            </v-col>
            <v-col cols="12" sm="3" v-if="fromPage === 'Completed'">
              <p class="grey--text mb-0">{{$_strings.shippingProblem.solution}}</p>
              <p class="black--text mt-0">{{shippingProblem.solution}}</p>
              <p class="grey--text mb-0">{{$_strings.shippingProblem.resolvedBy}}</p>
              <p class="black--text mt-0">{{shippingProblem.resolvedBy}}</p>
            </v-col>
            <v-col cols="12" sm="3" v-if="fromPage === 'Completed'">
              <p class="grey--text mb-0">{{$_strings.shippingProblem.resolvedDate}}</p>
              <p class="black--text mt-0">{{dateFormat(shippingProblem.resolvedDate)}}</p>
              <p class="grey--text mb-0">{{$_strings.shippingProblem.note}}</p>
              <p class="black--text mt-0" v-html="replaceNoteToHtml(shippingProblem.solutionNote)"></p>
            </v-col>
          </v-row>
        </v-card>
        <p>{{shippingProblem.foto}}</p>
        <v-card class="pa-2" outlined color="grey lighten-4">
          <v-row>
            <v-col v-for="(item, index) in  shippingProblem.imageUrl" :key="index" cols="12" sm="3">
              <viewer-image
                :src="item"
                height="200px"
              />
            </v-col>
          </v-row>
        </v-card>
        <br>
        <p>{{shippingProblem.location}}</p>
        <GmapMap
          :center='center'
          :zoom='15'
          map-type-id="terrain"
          style='width:100%;  height: 400px;'
        >
          <GmapMarker
            :position="{
              lat: Number(shippingProblem.latitude),
              lng: Number(shippingProblem.longitude)
            }"
            :clickable="true"
            :icon="{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}"
          />
        </GmapMap>
        <v-row v-if="fromPage === 'Process' && userAccess.canUpdate" class="mt-4" justify="end">
          <v-col cols="12" sm="3">
            <v-btn
              depressed
              medium
              block
              color="primary"
              class="white--text"
              @click="showDialogDoneProblem"
            >
              {{$_strings.shippingProblem.selesaikan}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <DialogDoneProblem
      ref="dialogDoneProblem"
      @fetchShippingProblem="fetchShippingProblem"
    />
  </v-container>
</template>

<script>
import { replaceNoteToHtml, dateFormat } from '@/helper/commonHelpers';
import DialogDoneProblem from './Dialog/DoneProblem.vue';

export default {
  name: 'problem-detail',
  mounted() {
    const { data } = this.$route.params;
    if (data) {
      this.setData(data);
      return;
    }
    this.fetchDetail();
  },
  components: {
    DialogDoneProblem,
  },
  data() {
    return {
      center: {
        lat: -6.1753917,
        lng: 106.82715329999996,
      },
      fromPage: this.$route.query.status || '',
      shippingProblem: [],
    };
  },
  methods: {
    replaceNoteToHtml,
    dateFormat,
    setDialogByDefault(dialog) {
      this[dialog] = !this[dialog];
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    fetchShippingProblem() {
      this.$router.go(-1);
    },
    setData(data) {
      this.shippingProblem = data;
      this.center = {
        lat: data.latitude,
        lng: data.longitude,
      };
    },
    fetchDetail() {
      const { id } = this.$route.params;
      this.$root.$loading.show();
      this.$_services.shippingProblem.getDetailShippingProblem(id)
        .then((res) => {
          this.setData(res.data);
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    showDialogDoneProblem() {
      const item = {
        cargoAccidentId: this.shippingProblem.id,
      };
      this.$refs.dialogDoneProblem.dialog = true;
      this.$refs.dialogDoneProblem.item = item;
    },
  },
};

</script>
