<template>
  <section>
    <!-- ONLY FOR LUMPSUM ORDERS -->
    <footer v-if="isShowFooterToUpdateLumpsumOrder">
      <v-container fluid>
        <v-row
          v-if="isShowButtonActionToUpadteLumpsumOrder"
          justify-sm="end"
        >
          <v-col v-if="isShowButtonAccident" cols="12" sm="auto" md="3">
            <v-btn
              block
              color="red"
              small
              :disabled="isLoadingShipmentGroup"
              class="white--text"
              @click="setDialogAccident"
            >
              {{$_strings.order.REPORT_THE_PROBLEM}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto" md="auto">
            <v-btn
              color="primary"
              block
              small
              :disabled="isLoadingShipmentGroup"
              @click="$emit('updateStatusCargo')"
            >
              {{textButtonCargoUpdate}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </footer>
    <footer v-else>
      <v-container fluid>
        <v-row
          justify-sm="end"
        >
          <v-col cols="12" sm="auto" md="2" class="mr-0">
            <v-btn
              color="primary"
              block
              outlined
              @click="$router.go(-1)"
            >
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </footer>
    <DialogAccident
      ref="dialogAccident"
      @fetchListCargo="fetchListCargo"
      :orderDetails="orderDetails"
    />
  </section>
</template>

<script>
import DialogAccident from '../Dialog/Accident.vue';

export default {
  props: {
    currentStatus: {
      type: String,
      default: () => '',
    },
    isBast: {
      type: Boolean,
      default: () => false,
    },
    isLumpSum: {
      type: Boolean,
      default: () => false,
    },
    isLoadingShipmentGroup: {
      type: Boolean,
      default: () => false,
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogAccident,
  },
  computed: {
    isShowFooterToUpdateLumpsumOrder() {
      const key = (this.orderDetails && this.isLumpSum && !this.isBast && this.currentStatus === 'ON_PROCESS');
      const condition1 = key && (this.orderDetails.currentSequence === 1 && !this.orderDetails.isCurrentSequenceArrived);
      const condition2 = key && (
        this.orderDetails.isCurrentSequenceArrived
        || this.orderDetails.currentSequence === 0
        || (
          this.orderDetails.currentSequence > 1
          && !this.orderDetails.isCurrentSequenceArrived
        )
      );
      const condition3 = key && !(this.orderDetails.currentSequence > 0
            && this.orderDetails.isCurrentSequenceArrived
            && !this.orderDetails.isCurrentSequenceFinish
      );
      const condition4 = key && !(
        (this.orderDetails.totalSequence === this.orderDetails.currentSequence)
        && this.orderDetails.isCurrentSequenceArrived
        && this.orderDetails.isCurrentSequenceFinish
      );
      const compareCondition = (
        ((condition1) || condition2)
          && condition3
          && condition4
      );
      return compareCondition;
    },
    isShowButtonActionToUpadteLumpsumOrder() {
      const condition = this.isLumpSum
              && (
                this.orderDetails
                && (
                  this.orderDetails.isLumpSum
                  && this.orderDetails.latestStatus.toLowerCase() !== 'ba_penerimaan_konfirmasi_shipper'
                )
              );
      return condition;
    },
    isShowButtonAccident() {
      return this.orderDetails && this.orderDetails.currentSequence > 0;
    },
    textButtonCargoUpdate() {
      const {
        currentSequence,
        isCurrentSequenceArrived,
        isCurrentSequenceFinish,
        totalSequence,
        subOrder,
      } = this.orderDetails;

      const itemsMultiPick = (subOrder && subOrder.multiPick && subOrder.multiPick.length && subOrder.multiPick[0].itemsMultiPick) || [];
      const itemsMultiDrop = (subOrder && subOrder.multiDrop && subOrder.multiDrop.length && subOrder.multiDrop[0].itemsMultiDrop) || [];
      if (currentSequence === 0) {
        return 'Berangkat Ke Lokasi Penjemputan Asal';
      }
      if (currentSequence === 1 && !isCurrentSequenceArrived) {
        return 'Sampai di Lokasi Penjemputan Asal';
      }
      // MULTIPICK
      if (isCurrentSequenceArrived && isCurrentSequenceFinish && itemsMultiPick.length && itemsMultiPick[currentSequence - 1]) {
        return `Berangkat di Lokasi Multipick ${currentSequence}`;
      }
      if (!isCurrentSequenceArrived && !isCurrentSequenceFinish && itemsMultiPick.length && itemsMultiPick[currentSequence - 2]) {
        return `Sampai di Lokasi Multipick ${currentSequence - 1}`;
      }
      // MULTIDROP
      let currentMultidropSequence = 0;
      if (itemsMultiDrop.length) {
        currentMultidropSequence = currentSequence - 1;
      }
      if (itemsMultiPick.length) {
        currentMultidropSequence = currentSequence - itemsMultiPick.length - 1;
      }
      if (isCurrentSequenceArrived && isCurrentSequenceFinish && itemsMultiDrop.length && itemsMultiDrop[currentMultidropSequence]) {
        return `Berangkat di Lokasi Multidrop ${currentMultidropSequence + 1}`;
      }
      if (itemsMultiDrop.length) {
        currentMultidropSequence = currentSequence - 2;
      }
      if (itemsMultiPick.length) {
        currentMultidropSequence = currentSequence - itemsMultiPick.length - 2;
      }
      if (!isCurrentSequenceArrived && !isCurrentSequenceFinish && itemsMultiDrop.length && itemsMultiDrop[currentMultidropSequence]) {
        return `Sampai di Lokasi Multidrop ${currentMultidropSequence + 1}`;
      }
      // DESTINATION
      if (currentSequence === totalSequence - 1 && isCurrentSequenceArrived && isCurrentSequenceFinish) {
        return 'Berangkat Ke Lokasi Pengiriman Akhir';
      }
      return 'Sampai di Lokasi Pengiriman Akhir';
    },
  },
  methods: {
    fetchListCargo() {
      this.$emit('fetchListCargo');
    },
    setDialogAccident() {
      const { id: shipmentCargoId } = this.$route.params;
      this.$refs.dialogAccident.shipmentCargoId = shipmentCargoId;
      this.$refs.dialogAccident.dialog = true;
    },
  },
};
</script>
