<template>
  <section>
    <h2
      v-if="documents.filter((doc) => doc.shipmentId === shipment.shipmentsId).length > 0"
      class="font-weight-bold black--text mb-4"
    >
      {{$_strings.order.DOCUMENTATION}}
    </h2>
    <v-row v-if="isLoadingGetDocumentPhoto">
      <v-col cols="12">
        <v-skeleton-loader
          type="heading"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="4" v-for="docImage in 3" :key="docImage">
        <v-skeleton-loader
          type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="isErrorGetDocumentPhoto && !isLoadingGetDocumentPhoto" class="mt-5">
      <v-col cols="12">
        <h4 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h4>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-icon @click="$emit('fetchOrderDocument')">
          mdi-reload
        </v-icon>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if="documents.filter((doc) => doc.shipmentId === shipment.shipmentsId).length === 0">
        <p class="text-center">Belum ada dokumentasi</p>
      </v-col>
      <v-col v-if="documents.filter((doc) => doc.shipmentId === shipment.shipmentsId).length > 0" cols="12">
        <v-row>
          {{documentName = ''}}
          <v-col
            v-for="(document, indexDocument) in documents.filter((doc) => doc.shipmentId === shipment.shipmentsId)"
            cols="12"
            md="4"
            :key="'doc'+indexDocument"
          >
            <v-row>
              <v-col
                :cols="orderDetails.isLumpSum && currentStatus === 'ON_PROCESS' ? '10' : '12'"
              >
                <div style="height: 50px; overflow:hidden;">
                  <p v-if="documentName !== document.name" class="grey--text mb-0">
                    {{documentName = document.customeName}}
                  </p>
                </div>
              </v-col>
              <v-col
                class="d-flex justify-center align-center"
                cols="2"
                v-if="isLumpSum && orderDetails.isLumpSum && currentStatus === 'ON_PROCESS'"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="red"
                      @click="showDialogDeleteConfirmation(document.shipmentCargoId, document.id)"
                      @click.stop.prevent >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$_strings.common.DELETE}}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <viewer-image
              :src="document.imageUrl"
              height="200px"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <DialogConfirmation
      ref="dialogConfirmation"
      @submit="deleteDocument"
    />
  </section>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';
import DialogConfirmation from '../Dialog/Confirmation.vue';

export default {
  components: {
    DialogConfirmation,
  },
  props: {
    currentStatus: {
      type: String,
      default: () => '',
    },
    isLoadingGetDocumentPhoto: {
      type: Boolean,
      default: () => false,
    },
    isErrorGetDocumentPhoto: {
      type: Boolean,
      default: () => false,
    },
    isLumpSum: {
      type: Boolean,
      default: () => false,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    shipment: {
      type: Object,
      default: () => {},
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      targetItemDeleteDocument: {},
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    deleteDocument() {
      const { shipmentCargoId, documentId } = this.targetItemDeleteDocument;
      this.$emit('deleteDocument', shipmentCargoId, documentId);
    },
    showDialogDeleteConfirmation(shipmentCargoId, documentId) {
      this.targetItemDeleteDocument = {
        shipmentCargoId,
        documentId,
      };
      this.$refs.dialogConfirmation.title = '<div class="font-weight-bold"><p class="text-center mb-0">Anda yakin,</p><p>Akan menghapus dokumen ?</p></div>';
      this.$refs.dialogConfirmation.dialog = true;
    },
  },
};
</script>
