<template>
  <v-card>
    <v-card-title>
      <h5>{{$_strings.order.ORDER_STATUS_LABEL}}</h5>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        v-if="isLoadingHistoryStatus"
        type="list-item-three-line, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader>
      <v-row v-else-if="isErrorGetHistoryStatus && !isLoadingHistoryStatus" class="mt-5">
        <v-col cols="12">
          <h4 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h4>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon @click="$emit('fetchHistoryStatus')">
            mdi-reload
          </v-icon>
        </v-col>
      </v-row>
      <section v-else-if="historyStatus.length && !isLoadingHistoryStatus" style="margin-left:-40px">
        <v-timeline v-for="(orderHistories, index) in historyStatus" dense align-top :key="index">
          <v-timeline-item color="blue lighten-2" fill-dot small right>
            <v-row>
              <v-col class="ma-0 pa-0">
                <p class="grey--text mb-0">
                  {{dateFormat(orderHistories.statusDateTime)}}
                </p>
                <p class="caption black--text mb-0">{{orderHistories.status}}</p>
                <p class="caption grey--text">
                  {{getNotes(orderHistories.status, orderHistories.notes)}}
                </p>
              </v-col>
              <v-col cols="auto" class="grey--text">{{timeFormat(orderHistories.statusDateTime)}}</v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </section>
    </v-card-text>
  </v-card>
</template>

<script>
import { dateFormat, timeFormat } from '@/helper/commonHelpers';

export default {
  props: {
    isLoadingHistoryStatus: {
      type: Boolean,
      default: () => false,
    },
    isErrorGetHistoryStatus: {
      type: Boolean,
      default: () => false,
    },
    historyStatus: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    getNotes(status, notes) {
      if (status === 'BA Penerimaan - Diterima Gudang') {
        let locationName = '';
        const lastLocation = this.historyStatus.find((history) => history.status === 'Di Lokasi Tujuan');
        if (lastLocation) locationName = lastLocation.notes;
        return locationName;
      }
      return notes;
    },
  },
};
</script>
