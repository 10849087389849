<template>
  <section>
    <v-skeleton-loader
      v-if="isLoadingShipmentGroup"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
    <v-row v-else-if="isErrorGetShipmentGroup && !isLoadingShipmentGroup" class="mt-5">
      <v-col cols="12">
        <h4 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h4>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-icon @click="$emit('fetchOrder')">
          mdi-reload
        </v-icon>
      </v-col>
    </v-row>
    <v-card v-else-if="orderDetails && orderDetails.cargoTitle" outlined>
      <v-card-actions class="grey lighten-5">
        <span class="font-weight-bold ml-2">
          {{orderDetails && orderDetails.cargoTitle}}
        </span>
        <v-btn
          v-if="
            currentStatus === 'ON_PROCESS'
              && (shipmentDetail && shipmentDetail.canChangeDriver)
              && (orderDetails && orderDetails.transportModel === 'LAND')
              && userAccess.canUpdate
          "
          small
          color="red"
          class="white--text ml-2"
          @click="setDialogChangeDriver"
        >
          {{$_strings.order.CHANGE_DRIVER}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="orderDetails && orderDetails.pickupDate"
          icon
          @click="showShipmentExpand = !showShipmentExpand"
        >
          <v-icon>{{ showShipmentExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition v-if="orderDetails && orderDetails.pickupDate">
        <div v-show="showShipmentExpand">
          <v-divider></v-divider>
          <v-card-text>
            <span class="grey--text">{{$_strings.order.DELIVERY_DATE}} : {{orderDetails && dateFormat(orderDetails.pickupDate)}}</span>
            <v-card class="mt-2">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="mb-0 mt-2 grey--text">{{$_strings.order.ORIGIN}}</p>
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      class="caption"
                      :value="orderDetails && orderDetails.originLocationName"
                      disabled>
                    </v-text-field>
                    <p class="mb-0 mt-2 grey--text">{{$_strings.order.TYPE_MODA}}</p>
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      class="caption"
                      disabled
                      :value="orderDetails && formatTypeModa(orderDetails.transportModel)">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p class="mb-0 mt-2 grey--text">{{$_strings.order.DESTINATION}}</p>
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      class="caption"
                      disabled
                      :value="orderDetails && orderDetails.destinationLocationName">
                    </v-text-field>
                    <p class="mb-0 mt-2 grey--text">{{$_strings.order.SHIPPER}}</p>
                    <v-text-field
                      outlined
                      hide-details
                      dense
                      class="caption"
                      disabled
                      :value="orderDetails && orderDetails.abbreviation">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
    <DialogChangeDriver
      ref="dialogChangeDriver"
      title="Ubah Kendaraan dan Sopir"
      :service="serviceUpdateDriverVehcile"
      :afterAction="navigateToWaitingOrderList"
    />
  </section>
</template>

<script>
import DialogChangeDriver from '../Dialog/ChangeDriver.vue';
import { dateFormat } from '../../../helper/commonHelpers';

export default {
  components: {
    DialogChangeDriver,
  },
  props: {
    currentStatus: {
      type: String,
      default: '',
    },
    isLoadingShipmentGroup: {
      type: Boolean,
      default: false,
    },
    isErrorGetShipmentGroup: {
      type: Boolean,
      default: false,
    },
    itemsModaInfo: {
      type: Array,
      default: () => [],
    },
    shipmentDetail: {
      type: Object,
      default: () => {},
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showShipmentExpand: true,
    };
  },
  methods: {
    dateFormat,
    formatTypeModa(type) {
      switch (type) {
        case 'AIR': return 'Udara';
        case 'LAND': return 'Darat';
        case 'SEA': return 'Laut';
        default: return '-';
      }
    },
    navigateToWaitingOrderList() {
      this.$router.push('/main/pesanan/list/waiting');
    },
    serviceUpdateDriverVehcile() {
      return this.$_services.order.saveDriverChange;
    },
    setDialogChangeDriver() {
      const { transportTypeId } = this.shipmentDetail;
      const { id: shipmentsCargoId } = this.$route.params;
      if (this.reFetchOrder) {
        this.$emit('fetchOrder');
        return;
      }
      this.$refs.dialogChangeDriver.shipment = this.shipmentDetail;
      this.$refs.dialogChangeDriver.form.shipmentCargoId = shipmentsCargoId;
      this.$refs.dialogChangeDriver.transportTypeId = transportTypeId;
      this.$refs.dialogChangeDriver.dialog = true;
    },
  },
};
</script>
